<template>
<div>
  <vs-button color="primary" size="small" v-if="file != 'null' && file" :href="link">Download</vs-button>
  <span v-else>-</span>
<!--  <vs-button color="primary" type="border" size="small" v-else @click="() => params.openPopupUpload(this.params.data)">Upload</vs-button>-->
</div>
</template>

<script>
export default {
  name: "CellRendererFile",
  computed: {
    file () {
      return this.params.data.file;
    },
    link () {
      return this.params.storage().incomingLetter + this.params.data.file;
    }
  }
}
</script>

<style scoped>

</style>
