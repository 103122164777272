<template>
  <div class="flex items-center">
    <a href="" class="text-primary" @click.prevent="open">{{params.value}}</a>
<!--    <router-link :to="url" @click.stop.prevent class="text-primary">{{ params.value }}</router-link>-->
  </div>
</template>

<script>
export default {
  name: 'CellRendererLink',
  computed: {
    encrypt() {
      let id = this.params.data.id;
      return this.$secure.encrypt(id);
    },

    url () {
      return { name: 'incoming-letter-show',  params: { id: this.encrypt } };
    },

    open () {
      this.params.openPopupDetail(this.params.data);
    }
  }
}
</script>
