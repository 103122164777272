<template>
  <div>
    <div class="flex">
      <vs-button color="primary" icon-pack="feather" icon="icon-edit" @click="$router.push({ name: 'incoming-letter-edit', params: { id: $route.params.id } })" class="mr-4">
        {{ $i18n.t('Edit') }}
      </vs-button>
      <vs-button color="danger" icon-pack="feather" icon="icon-trash" type="border" @click="confirmDeleteRecord">
        {{ $i18n.t('Delete') }}
      </vs-button>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/2" v-for="(cell, i) in cells" :key="i">
        <div class="mt-6">
          <h6 class="font-bold">{{ cell.header }}</h6>
              <p>
                <span v-if="cell.format">
                  {{ cell.format(item[cell.field]) }}
                </span>
                <span v-else>
                  {{ item[cell.field] }}
                </span>
              </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Detail",
  data: () => ({
    loaded: false
  }),
  props: ['item'],
  computed: {
    cells () {
      return [
        {
          header: this.$i18n.t('Receiver'),
          field: 'penerima'
        },
        {
          header: this.$i18n.t('DateOfReceipt'),
          field: 'tanggal_terima_display'
        },
        {
          header: this.$i18n.t('LetterNumber'),
          field: 'nomor_surat'
        },
        {
          header: this.$i18n.t('LetterDate'),
          field: 'tanggal_surat_display'
        },
        {
          header: this.$i18n.t('CompanyName'),
          field: 'nama_perusahaan'
        },
        {
          header: this.$i18n.t('Adress'),
          field: 'alamat'
        },
        {
          header: this.$i18n.t('ContactPerson'),
          field: 'cp'
        },
        {
          header: this.$i18n.t('PhoneFax'),
          field: 'telp_fax'
        },
        {
          header: this.$i18n.t('Subject'),
          field: 'perihal'
        },
        {
          header: this.$i18n.t('Page'),
          field: 'page'
        },
        {
          header: this.$i18n.t('Purpose'),
          field: 'tujuan_surat'
        },
        {
          header: this.$i18n.t('FileLocation'),
          field: 'lokasi_file'
        }
      ]
    }
  },
  methods: {
    confirmDeleteRecord () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Are you sure to delete incoming letter by subject "${this.item.perihal}" and receiver ${this.item.penerima} ?`,
        accept: this.deleteRecord,
        acceptText: 'Delete'
      })
    },
    deleteRecord () {
      this.$store.dispatch("incomingLetter/removeItem", this.item.id)
        .then((resp)   => {

          this.$emit('refresh');

          this.showDeleteSuccess(resp)
        })
        .catch(err => {
          this.showDeleteError(err)
        })
    },
    showDeleteSuccess (resp) {
      this.$vs.notify({
        color: 'success',
        title: 'Delete success',
        text: resp.data.message
      })
    },
    showDeleteError (err) {
      this.$vs.notify({
        title:'Opps something error',
        text: err.data.message,
        color:'danger'
      })
    }
  },
}
</script>

<style scoped>

</style>
