<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <feather-icon icon="EditIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" />
      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 mr-4 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />

      <feather-icon icon="UploadIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="upload" />

    </div>
</template>

<script>
export default {
  name: 'CellRendererActions',
  computed: {
    encrypt() {
      let id = this.params.data.id;
      return this.$secure.encrypt(id);;
    }
  },
  methods: {
    upload () {
      this.params.openPopupUpload(this.params.data);
    },
    showData () {
      this.params.openPopupDetail(this.params.data);
      // this.$router.push({name: 'incoming-letter-show', params: { id: this.encrypt }});
    },
    editRecord () {
      this.$router.push({name: 'incoming-letter-edit', params: { id: this.encrypt }});
    },
    confirmDeleteRecord () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Are you sure to delete incoming letter by subject "${this.params.data.perihal}" and receiver ${this.params.data.penerima} ?`,
        accept: this.deleteRecord,
        acceptText: 'Delete'
      })
    },
    deleteRecord () {
      this.$store.dispatch("incomingLetter/removeItem", this.params.data.id)
        .then((resp)   => {

          this.params.getData();

          this.showDeleteSuccess(resp)
        })
        .catch(err => {
          this.showDeleteError(err)
        })
    },
    showDeleteSuccess (resp) {
      this.$vs.notify({
        color: 'success',
        title: 'Delete success',
        text: resp.data.message
      })
    },
    showDeleteError (err) {
      this.$vs.notify({
        title:'Opps something error',
        text: err.data.message,
        color:'danger'
      })
    }
  }
}
</script>
